<template>
  <v-dialog
    v-if="displayDialog"
    v-model="displayDialog"
    :width="pageWidth"
    :overlay-opacity="0.8"
    @keydown.esc.stop.prevent="displayDialog = false"
    @click:outside="displayDialog = false"
    scrollable
  >
    <!-- The card ensures that the background is correct -->
    <!-- The if condition is for assertions in automation tests -->
    <v-card aut-page-dialog v-if="displayDialog" class="behaviour_dialog">
      <v-card-text class="pa-0" :style="`min-height: ${minHeight}`">
        <CloseButton @close="displayDialog = false"></CloseButton>
        <UnderConstruction v-if="showUnderConstruction" />
        <v-card-text v-if="show404" class="pa-10 text-center">
          <span v-if="!userCanPerformAction">{{ NO_PAGE_REQUEST_ADMIN }}</span>
          <span v-else>{{ MESSAGES.NO_PAGE }}</span>
        </v-card-text>
        <div
          v-else-if="!page"
          aut-loader
          class="text-center behaviour_dialog_loader"
          :style="`min-height: ${minHeight}`"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <Page
          :key="pageKey"
          class="behavior_dialog_page"
          v-else-if="page"
          :page="dialogPage"
          :access_control="accessControl"
          :context="currentContext"
        />
      </v-card-text>
      <v-card-actions v-if="show404 && userCanPerformAction">
        <v-spacer></v-spacer>
        <v-btn @click="displayDialog = false" aut-confirmation-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="navigateToEditor"
          aut-confirmation-confirm
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { navigateTo } from "@/util.js";
import { pageMixin } from "@/components/mixin.js";
import { mapState } from "vuex";
import { defaultsDeep } from "lodash";
import { MESSAGES } from "@/constants";
import CloseButton from "@/components/pageContent/CloseButton.vue";

const debug = require("debug")("atman.components.page_dialog");
debug(`page_dialog`);
export default {
  name: "PageDialog",
  mixins: [pageMixin],
  components: {
    CloseButton,
  },
  data() {
    return {
      show404: false,
      defaultMinHeight: "400px",
    };
  },
  created() {
    this.MESSAGES = MESSAGES;
  },
  watch: {
    "dialog.content"() {
      this.currentContext = this.dialog?.context;
      if (this.dialog.content === false) {
        this.defaultMinHeight = "50px";
        this.show404 = true;
      } else {
        this.show404 = false;
        const page = this.dialog?.content?.data;
        this.page = page;
        debug(`page is now`, this.page, this.currentContext);
      }
    },
  },
  computed: {
    ...mapState(["dialog"]),
    userCanPerformAction() {
      return this.$store.getters[`user/canPerformAction`];
    },
    showUnderConstruction() {
      return this.dialog?.showUnderConstruction;
    },
    pageWidth() {
      return this.isSmallScreen
        ? "100vw"
        : this.page?.display?.width || "800px";
    },
    minHeight() {
      return this.page?.display?.min_height || this.defaultMinHeight;
    },

    displayDialog: {
      get() {
        return this.dialog.display;
      },
      set(value) {
        return this.$store.commit("togglePageDialog", value);
      },
    },
    dialogPage() {
      const pageContents = (this.page?.content || []).map((pageContent) => {
        const classes = [
          ...(pageContent?.display?.classes || []),
          "transparent",
        ];
        return defaultsDeep(
          {},
          {
            display: {
              attributes: {
                flat: true,
              },
              classes,
            },
          },
          pageContent
        );
      });
      const page = Object.assign(
        {},
        this.page,
        { content: pageContents },
        {
          classes: "behavior-no-pin behavior-no-fav",
        }
      );
      return page;
    },
  },
  methods: {
    navigateToEditor() {
      const path = encodeURIComponent(this.currentContext.full_path);
      navigateTo(this.$router, `/editor_page?page=${path}&operation=create`);
      this.displayDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.behaviour_dialog_loader {
  min-height: 200px;
  padding-top: 17%;
  padding-bottom: 10%;
}
.behavior_dialog_page {
  padding: 0px !important;
}
::v-deep {
  .behavior_content_title {
    padding-bottom: 0px !important;
  }
}
</style>
